import React from 'react';
import { graphql } from 'gatsby';
import {
  ColorDataTableDesign,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../data/pages/guidelines.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Content Selection"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Content Selection" />

      <PageNavigation>
        <PageNavigationLink>State</PageNavigationLink>
        <PageNavigationLink>Type</PageNavigationLink>
        <PageNavigationLink>Mobile</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Values</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="State">
        <Paragraph>
          Selectable content items have three states: <em>default</em>{' '}
          (unselected), <em>hovered</em> and <em>selected</em>. States are
          important in showing whether the content is selected or not
          (unselected and selected) and to signal to the user that content is{' '}
          <em>selectable</em> (hovered). All forms of our content selection
          patterns below have all three states.
        </Paragraph>
      </Section>

      <Section title="Type">
        <img
          src={require('../../../imgs/diagrams/selection-types.gif')}
          className="uni-pad--one--btm"
        />

        <SectionSubhead>Background</SectionSubhead>
        <Paragraph>
          This type is best suited for text-based selectable regions with
          visible backgrounds, like a table-cell. If a user selects a piece of
          associated content, this can be coupled with the edge type. In a
          multi-selection mode, be sure to use the mark type as well.
        </Paragraph>

        <SectionSubhead>Edge</SectionSubhead>
        <Paragraph>
          If a user selects a piece of associated content, place a 4px stripe
          adjacent to the content. This type doesn’t have a multi-selection
          mode.
        </Paragraph>

        <SectionSubhead>Outline</SectionSubhead>
        <Paragraph>
          Best suited for visual content, such as video thumbnails on a grid,
          that don’t have a defined background. Inset a thick 4px outline on the
          content when it’s selected. In a multi-selection mode, be sure to use
          the mark type as well.
        </Paragraph>

        <SectionSubhead>Mark</SectionSubhead>
        <Paragraph>
          Only use this for multi-selection mode. The mark denotes that multiple
          items can be selected. An example of this would be selecting a group
          of videos to share. The mark should typically be used in conjunction
          with one of the types above. More details are available on the select
          mark{' '}
          <Link href="/components/select-mark" isDesignCodeLink>
            component page
          </Link>
          .
        </Paragraph>
      </Section>
      <Section title="Mobile">
        <Paragraph>
          There should be no changes to how content is selected on mobile, but
          special attention should be given to tapping and gestures. To match
          how content selection operates on the web, make use of hover styles
          for tapping content when the tap opens an item or new view.
        </Paragraph>
        <Paragraph>
          Mobile navigation should always adopt the selection background and
          edge to display the associated content.
        </Paragraph>
      </Section>
      <Section title="Usage">
        <SectionSubhead>Background Region</SectionSubhead>
        <Paragraph>
          The background color should stretch to the edges of the selected
          region.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="partially fill a background region."
            img="contentselection-backgroundregion-dont"
          />
          <DontDoItem
            type="do"
            text="fill the entire background with the appropriate selection color."
            img="contentselection-backgroundregion-do"
          />
        </DontDo>

        <SectionSubhead>Background Dividers</SectionSubhead>
        <Paragraph>
          Dividers adjacent to a selected (or hovered) region should be replaced
          with Level 0 lines.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use level accents to divide a selected region."
            img="contentselection-backgrounddividers-dont"
          />
          <DontDoItem
            type="do"
            text="replace dividers with edge-to-edge Level 0 lines to emphasize the user's selection."
            img="contentselection-backgrounddividers-do"
          />
        </DontDo>

        <SectionSubhead>Edge Placement</SectionSubhead>
        <Paragraph>
          When a selection reveals additional content, the Select Edge should
          always be on the side adjacent to the revealed content. This helps
          associate the item with the content and is consistent with tab
          navigations.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="place the edge on the opposite edge of the related content."
            img="contentselection-edgeplacement-dont"
          />
          <DontDoItem
            type="do"
            text="place the edge adjacent to the related content."
            img="contentselection-edgeplacement-do"
          />
        </DontDo>

        <SectionSubhead>Text Contrast</SectionSubhead>
        <Paragraph>
          Use increased text contrast for selected and hover states of
          text-based selectable items. On the flip side, unselected items may
          have reduced contrast to support the selected item.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use low contrast text color for selected content."
            img="contentselection-textcontrast-dont"
          />
          <DontDoItem
            type="do"
            text="increase text contrast to emphasize selectable and selected content."
            img="contentselection-textcontrast-do"
          />
        </DontDo>
      </Section>

      <Section title="Values">
        <Section>
          <SectionSubhead>Light Environment</SectionSubhead>
          <ColorDataTableDesign data={data} colorsType="light" />
        </Section>

        <Section>
          <SectionSubhead>Dark Environment</SectionSubhead>
          <ColorDataTableDesign data={data} env="dark" colorsType="dark" />
        </Section>
      </Section>

      <Section title="Platform">
        <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
          <PlatformTableRow platform="web" />
          <PlatformTableRow platform="apple" />
          <PlatformTableRow platform="android" />
        </PlatformTable>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query contentSelectionColorsDesign {
    allColorsYaml(filter: { type: { eq: "Selection" } }) {
      edges {
        node {
          type
          light_environment_colors {
            value
            common_name
          }
          dark_environment_colors {
            value
            common_name
          }
        }
      }
    }
  }
`;
